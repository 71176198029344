@import "rsuite/dist/rsuite.css";

.clientLists,
.userLists {
  max-width: 95%;
  margin: auto;
}

.userLists .userDetails .rounded {
  /* background-color: #f58e0e; */
  color: var(--text-color) !important;
  font-size: 20px;
}

.userLists .userDetails .nav {
  justify-content: space-between;
}

.details {
  background-color: var(--bg-color);
  padding: 7px;
}

.videoDetails {
  /* background-color: var(--bg-color); */
  border-radius: 10px;
  padding: 20px;
  height: auto;
  overflow-x: auto;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  overflow-y: auto;
}

.user-table th,
.user-table td {
  padding: 5px 5px;
  font-size: 14px;
  text-align: left;
  color: var(--text-color);
  background-color: var(--bg-chart);
  white-space: initial;
}

.user-table thead tr:first-child th {
  border-top: none;
  /* Remove top border */
}

.user-table tbody tr:last-child td {
  border-bottom: none;
}

.user-table th:first-child,
.user-table td:first-child {
  border-left: none;
}

.user-table th:last-child,
.user-table td:last-child {
  border-right: none;
  width: 100px;
}

thead {
  background-color: var(--bg-color);
  color: var(--text-color);
}

.user-table-container th,
.user-table-container td {
  padding: 5px;
}

.user-table th {
  text-align: left;
  font-weight: bold;
}

.user-table-container {
  max-height: 70vh; /* Adjust height as needed */
  overflow-y: auto;
  display: block;
  font-size: 11px;
  border: 1px solid #ccc; /* Optional: adds border around table */
}

.user-table tbody {
  display: block;
  overflow: auto;
  max-height: 500px; /* Ensures it fits inside container */
}

.user-table thead,
.user-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.clear-cache .cache-button {
  border: none;
  background-color: #f58e0e;
  color: var(--text-color);
}

.create_user button,
.submitext-center,
.create_role .rounded-3,
.create_permission .rounded-3 {
  border: none;
  background-color: rgb(15, 125, 15);
  color: var(--text-color);
}

.edit {
  border: none;
  padding: 8px 15px;
  border-radius: 10px;
  background: linear-gradient(to right, #6c3bbc 0%, #9467bd 100%);
  color: var(--text-color);
}

.noedit,
.noView {
  padding: 8px 15px;
  margin: 2px 0px;
  border-radius: 10px;
  background: rgba(246, 38, 38, 0.814);
  color: var(--text-color);
}

.view {
  background-color: green;
  padding: 8px 15px;
  border-radius: 10px;
  color: var(--text-color);
  border: none;
}

.model-popup-close {
  border: none;
  background: red;
  color: var(--text-color);
  font-size: 16px;
  text-align: center;
  margin-left: 20px;
}

/*.rolelistingDropdown {
  padding: 10px !important;
  margin: 0px 15px !important;
  border: 1px solid rgb(234, 233, 233);
  width: 100%;
  border-radius: 5px;
}*/

.userLists .modal {
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.userLists .modal-dialog {
  background-color: var(--bg-color);
  border-radius: 5px;
  padding: 20px;
  top: 15vh;
}

.userLists .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userLists .modal-title {
  margin: 0;
}

.userLists .modal-popup-close {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.userLists .modal-body input {
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid black;
}

.userLists .modal-body button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
}

.userLists .modal-footer {
  margin-top: 20px;
  text-align: right;
}

/* pages.css */

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.custom-modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 500px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.custom-modal-header h5 {
  margin: 0;
}

.custom-modal-close {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.custom-input-text {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  box-sizing: border-box;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.custom-button {
  padding: 10px;
  background-color: #007bff;
  color: var(--text-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.custom-button:hover {
  background-color: #0056b3;
}

.permission_header,
.Role_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-modal-close {
  background-color: red;
  color: var(--text-color);
  border-radius: 10px;
  padding: 8px 10px;
  margin: 5px;
}

/* .alert-warning {
  z-index: 1050;
  color: var(--text-color) !important;
  margin: 0 0 auto;
} */
/* .alert-heading {
} */
.alert-warning {
  position: relative;
  background-color: var(--bg-color) !important;
  margin-right: auto;
  margin-left: auto;
}

.modal-parent {
  z-index: 1055;
  position: fixed;
  top: 0;
  left: 0;
  width: 500px;
  padding: 1rem;
  margin: 0.5rem;
  /* height: 100%; */
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  /* width: 100%; */
  color: var(--text-color) !important;
  background-color: var(--bg-color) !important;
}

.loader-overlay,
.custom-alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: var(--bg-color); */
  color: var(--bg-color) !important;
  background-color: #000;
  opacity: 0.5;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  z-index: 99;
}

input.rs-date-range-input.rs-input {
  padding: 3px !important;
  margin: 3px !important;
  font-size: 16px !important;
  width: 215px !important;
}

.multi-select-container {
  width: 80% !important;
  min-width: "200px";
}

.chart-title {
  text-align: center;
  /* display: inline;
  justify-content: center; */
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: black !important;
}

.limit-dropdown {
  width: 70px !important;
}

.limit-filter {
  display: flex;
  justify-content: end;
}

.glassy {
  background: rgba(255, 255, 255, 0.2);
  /* Semi-transparent white background */
  backdrop-filter: blur(10px);
  /* Blurring effect for the background */
  -webkit-backdrop-filter: blur(10px);
  /* Safari support */
  border-radius: 10px;
  /* Rounded corners */
  border: 1px solid rgba(255, 255, 255, 0.3);
  /* Border to enhance the glassy look */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
  padding: 20px;
  /* Some padding inside the glassy box */
  color: black;
  /* Text color */
}

.font-size-11 {
  font-size: 11px !important;
}

.sync-tiktok-details {
  text-align: end;
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-color) !important;
}

.chart-container {
  height: 300px;
  /* Set your preferred fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Ensure the chart canvas fills the container */
.chart-container canvas {
  height: 100% !important;
  width: 100% !important;
}

.dropdown-container .dropdown-content,
.dropdown-heading,
select {
  color: #000000;
}

.sync-container {
  background-color: #f9f9f9;
  /* Light background for contrast */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 20px auto;
  text-align: center;
}

.sync-header h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 15px;
}

.sync-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.sync-button {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.sync-button.loading {
  background-color: #ccc;
  /* Disabled state */
  cursor: not-allowed;
}

.sync-button:hover:not(.loading) {
  background-color: #0056b3;
  color: #fff;
}

.countdown-timer {
  margin-top: 15px;
}

.countdown-label {
  font-size: 0.9rem;
  color: #666;
}

.countdown-value {
  font-size: 1.2rem;
  color: #ff5722;
  /* Highlighted countdown color */
  font-weight: bold;
}

.heading-color {
  color: #ffffff !important;
}

.sign-up-page {
  color: #fff !important;
}

span.ms-2.sign-up-page {
  cursor: pointer;
}

.sign-up-page-bg {
  background-color: transparent !important;
}

.recaptcha {
  padding: 0px 0px 0 9px;
}

.recaptcha-signin {
  padding: 0px 0px 0 20px;
}

.bgimg {
  position: relative;
  font-size: 25px;
}

.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 150%);
  text-align: center;
  background-color: snow;
  border-radius: 10px;
}

p.error {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin: 15px 0 15px 0px !important;
}

p.mt-3.mb-3.font-weight-bold {
  font-weight: bold;
  text-align: center;
}

.rs-picker-popup.rs-picker-popup-daterange {
  z-index: 9999;
}

/* Coming Soon Page Styles */
.coming-soon-container {
  padding: 2rem;
  justify-content: center;
  display: flex;
  padding: 2rem;
}

.coming-soon-content {
  text-align: center;
  max-width: 1200px;
  width: 100%;
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.coming-soon-content h1 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 1rem;
}

.environment-badge {
  display: inline-block;
  padding: 0.5rem 1rem;
  background: #e2e8f0;
  border-radius: 20px;
  font-size: 0.875rem;
  color: #4a5568;
  margin-bottom: 2rem;
}

.pricing-section {
  margin-top: 2rem;
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .coming-soon-content {
    padding: 1rem;
  }

  .coming-soon-content h1 {
    font-size: 2rem;
  }

  .pricing-section {
    margin-top: 1rem;
  }
}

.subscription-message {
  font-size: 1.2rem;
  color: #4a5568;
  margin: 1rem 0 2rem;
  font-weight: 500;
}

/* Settings UI Styles */
.settings-container {
  padding: 1rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.settings-title {
  color: #2d3748;
  font-weight: 600;
  font-size: 1.25rem;
}

.settings-form {
  max-width: 600px;
  background: none;
}

.settings-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.settings-group .form-check {
  margin: 0;
}

.settings-group .form-check-input {
  width: 3rem;
  height: 1.5rem;
  margin-top: 0.25rem;
}

.settings-group .form-check-input:checked {
  background-color: #3182ce;
  border-color: #3182ce;
}

.settings-group .form-label {
  font-weight: 500;
  color: #2d3748;
}

.settings-group small {
  font-size: 0.875rem;
  color: #718096;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .settings-container {
    padding: 0.75rem;
  }

  .settings-title {
    font-size: 1.1rem;
  }

  .settings-group .form-check-input {
    width: 2.5rem;
    height: 1.25rem;
  }
}
