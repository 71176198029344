.dashboard {
  height: 100vh;
  width: 100%;
}

.dashboard-container {
  display: flex;
  background-color: var(--bg-content);
}

.content {
  background-color: var(--bg-content) !important;
  min-height: 100vh;
  height: auto;
}
.dashboard.content {
  margin-left: 250px;
  /* Adjust this value according to your sidebar width */
  flex-grow: 1;
  /* padding: 20px; */
  transition: margin-left 0.3s ease;
  overflow: hidden;
}

.ProgressBar.collapsed ~ .dashboard.content {
  margin-left: 80px;
  /* Adjust this value according to the collapsed sidebar width */
}

.create_user_heading {
  color: #55bb9b;
  font-size: 28px !important;
  font-weight: 400;
}

.form-control,
select {
  background-color: #ffffff !important;
}

.creatrUserSubmit {
  background: #55bb9b !important;
  border: none !important;
  padding: 8px 15px !important;
  border-radius: 30px !important;
  font-weight: 600 !important;
}

.creatrUserClose {
  background: #262626 !important;
  border: none !important;
  padding: 8px 25px !important;
  border-radius: 30px !important;
  font-weight: 600 !important;
}

.inactive-status {
  background: rgb(239 67 67);
  color: var(--text-color);
  padding: 5px 8px;
  border-radius: 10px;
}

.active-status {
  background: green;
  color: var(--text-color);
  padding: 5px 8px;
  border-radius: 10px;
}
.bg-chart {
  background-color: var(--bg-chart) !important;
  color: var(--text-color) !important;
}
