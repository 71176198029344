/* Add this to your global CSS */
:fullscreen,
::backdrop {
  background-color: inherit;
}

:-webkit-full-screen {
  background-color: inherit;
}

:-ms-fullscreen {
  background-color: inherit;
}
