.sign-up,
.sign-in {
  width: 100%;
  height: 100vh;
  background: linear-gradient(to right, #6c3bbc 0%, #9467bd 100%);
}

.contain {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

form {
  height: auto;
  background-color: var(--bg-color);
  border-radius: 15px !important;
  backdrop-filter: blur(10px);
  /* Adjust the blur value as needed */
  -webkit-backdrop-filter: blur(40px);
  height: auto;
  background-color: var(--bg-color);
  border-radius: 15px !important;
}

h3 {
  font-size: 42px !important;
  text-align: center !important;
  font-weight: 800 !important;
  color: white !important;
  margin-top: 10px !important;
}

.email_login,
.password,
.inp_name {
  font-size: 28px !important;
  font-weight: 600 !important;
  color: rgba(255, 255, 255, 0.821);
  text-align: center;
}

.loginFields {
  padding: 8px !important;
  width: 310px !important;
  margin: 15px !important;
  font-size: 20px !important;
  border-radius: 5px !important;
  border: none;
  color: #000;
}

.loginFields:focus {
  outline: none;
}

input.form-control {
  padding: 8px !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  border: none;
  color: #000;
}

select.form-control {
  padding: 8px !important;
  margin: 15px !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  border: none;
  color: #000;
}

input:focus {
  outline: none;
}

.button {
  width: 100% !important;
  display: flex !important;
  justify-content: center;
  margin-top: 20px !important;
}

.submit {
  background: linear-gradient(to right, #6c3bbc 0%, #9959d4 100%);
  border: none;
  border-radius: 15px !important;
  padding: 10px 40px !important;
  color: white;
  font-weight: 500 !important;
  font-size: 20px !important;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  margin-left: 10px !important;
  margin-top: 20px !important;
}

.submit-2 {
  background: linear-gradient(to right, #6c3bbc 0%, #9959d4 100%);
  border: none;
  border-radius: 15px !important;
  padding: 10px 40px !important;
  color: white;
  font-weight: 500 !important;
  font-size: 20px !important;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.submit:hover {
  transform: scale(1.1);
  background: linear-gradient(to right, #c471f5 0%, #ac32c880 100%);
}

.newuser {
  color: rgb(190, 190, 190);
  font-size: 18px !important;
  margin-top: 20px !important;
  display: block !important;
}

.account {
  color: white !important;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  form {
    padding: 40px;
    padding: 40px;
  }

  h3 {
    font-size: 36px !important;
  }

  input {
    width: 250px !important;
    margin: 10px !important;
    font-size: 16px !important;
  }

  button {
    padding: 10px 40px !important;
    font-size: 18px !important;
  }

  .newuser {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 480px) {
  form {
    padding: 20px !important;
  }

  h3 {
    font-size: 32px !important;
  }

  input {
    width: 200px !important;
    margin: 8px !important;
    font-size: 14px !important;
  }

  button {
    padding: 8px 30px !important;
    font-size: 16px !important;
  }

  .newuser {
    font-size: 14px !important;
  }
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  /* Black background with opacity */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 4px solid #f3f3f3;
  /* Light grey border */
  border-top: 4px solid #f58e0e;
  /* Orange border on top */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* ------------------------------------------------ */

:root {
  --purple: #8a15ff;
  --blue: #3800e7;
  --ltblue: #15e0ff;
  --magenta: #d000c5;
}

/* signInBackground {
  background: linear-gradient(var(--blue), var(--purple)) !important;
  height: 100vh;
} */

h1 {
  font-size: 3em;
  margin-bottom: 0.2em;
}

h2 {
  font-size: 2em;
}

.main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  font-weight: 300;
  overflow: hidden;
  color: var(--text-color) !important;
  background: linear-gradient(var(--blue), var(--purple)) !important;
  text-align: center;
  align-items: center;
  font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.main:before,
.main:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -3;
}

.main:before {
  right: 0;
  bottom: -19;
  height: 30em;
  width: 30em;
  border-radius: 30em;
  background: linear-gradient(var(--blue), var(--purple));
  align-self: flex-end;
  animation: gradient-fade 8s ease-in-out 3s infinite alternate;
}

.main:after {
  --circle-unit: 10em;
  top: 0;
  left: 30;
  height: var(--circle-unit);
  width: var(--circle-unit);
  border-radius: var(--circle-unit);
  background: linear-gradient(var(--blue), var(--purple));
  animation: gradient-fade-alt 6s ease-in-out 3s infinite alternate;
}

.main__text-wrapper {
  position: relative;
  padding: 2em;
}

.main__text-wrapper:before,
.main__text-wrapper:after {
  content: "";
  display: block;
  position: absolute;
}

.main__text-wrapper:before {
  --circle-unit: 13em;
  z-index: -1;
  top: -3em;
  right: -3em;
  width: var(--circle-unit);
  height: var(--circle-unit);
  opacity: 0.7;
  border-radius: var(--circle-unit);
  background: linear-gradient(var(--ltblue), var(--purple));
  animation: rotation 7s linear infinite;
}

.main__text-wrapper:after {
  --circle-unit: 20em;
  z-index: -1;
  bottom: calc(-1 * var(--circle-unit));
  width: var(--circle-unit);
  height: var(--circle-unit);
  border-radius: var(--circle-unit);
  background: linear-gradient(var(--magenta), var(--purple));
  animation: rotation 7s linear infinite;
}

.arrow {
  z-index: 1000;
  opacity: 0.5;
  position: absolute;
}

.arrow--top {
  top: 0;
  left: -5em;
}

.arrow--bottom {
  bottom: 0;
  right: 3em;
}

.dotted-circle {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.3;
  animation: rotation 38s linear infinite;
  z-index: -1;
}

/* Animations */
.draw-in {
  stroke-dasharray: 1000;
  stroke-dashoffset: 10;
  animation: draw 15s ease-in-out alternate infinite;
}

a.text-decoration-none.dashboard-link {
  color: var(--text-color);
}

a.text-decoration-none.dashboard-link:hover {
  background-color: var(--bg-color);
  color: var(--text-color);
  padding: 5px;
  border-radius: 10px;
}

@keyframes draw {
  from {
    stroke-dashoffset: 1000;
  }

  to {
    stroke-dashoffset: 0;
  }
}

.item-to {
  animation-duration: 10s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
}

.bounce-1 {
  animation-name: bounce-1;
  animation-timing-function: ease;
}

.bounce-2 {
  animation-name: bounce-2;
  animation-timing-function: ease;
}

.bounce-3 {
  animation-name: bounce-3;
  animation-timing-function: ease;
}

h3.client-name {
  color: #000 !important;
}

.days-filter {
  margin-bottom: 20px;
}

.days-filter label {
  margin-right: 10px;
}

@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(50px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bounce-3 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes gradient-fade {
  from {
    transform: translate(10%, -10%) rotate(0deg);
  }

  to {
    transform: translate(50%, -50%) rotate(360deg);
  }
}

@keyframes gradient-fade-alt {
  from {
    transform: translate(-20%, 20%) rotate(0deg);
  }

  to {
    transform: translate(-60%, 60%) rotate(360deg);
  }
}

.forgot-password {
  margin-top: 0.5rem;
  padding: 0 10px 0px 0;
}

.forgot-password a {
  color: #fff;
  font-size: 0.99rem;
  transition: color 0.2s ease;
}

.forgot-password a:hover {
  color: #000;
}

.success-message {
  background-color: #c6f6d5;
  color: #2f855a;
  padding: 0.75rem;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
}

.forgot-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  ;
}